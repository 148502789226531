<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="['card-row', {rounded: !this.expand}]">
    <template #header>
      <b-container fluid class="px-1 py-2">
        <b-row :class="{clickable: contract}" @click.self="expand = !expand">
          <b-col cols="4" :class="{clickable: contract}" @click.self="expand = !expand">
            {{ contract.displayname }}
            <b-icon-caret-down-fill v-if="contract" :flip-v="expand" @click="expand = !expand"/>
          </b-col>
          <b-col cols="6" :class="['ml-auto', {clickable: contract}]" @click.self="expand = !expand">
            <BadgeCounterContract :contract="contract"/>
          </b-col>
          <b-col cols="auto" class="clickable" @click.self="expand = !expand">
            <b-icon-pencil-square :id="'tt-editContract' + contract.id" class="clickable mr-2"
                                  @click="editContract(contract)"/>
            <b-tooltip :target="'tt-editContract' + contract.id" triggers="hover">Bearbeiten</b-tooltip>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <ContractDetails v-if="expand" :contract="contract" @refresh="refresh"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import TransitionExpand from "@/components/utility/TransitionExpand";
import ContractDetails from "@/components/fg-software/contracts/ContractDetails.vue";
import BadgeCounterContract from "@/components/fg-software/badgeCounter/BadgeCounterContract.vue";

export default {
  name: "ContractRow",
  props: ['contract', 'groupId'],
  components: {
    BadgeCounterContract,
    ContractDetails,
    TransitionExpand
  },
  data() {
    return {
      expand: this.extended
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    editContract(data) {
      data.groupId = this.groupId
      this.$emit('edit-contract', data)
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
