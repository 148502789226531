<template>
  <b-row class="mb-2">
    <b-col cols="4" v-if="contractExpiring.length > 0">
      Diese Verträge laufen in weniger als 60 Tagen ab
      <b-icon-caret-down-fill :flip-v="contractExpiringExpand" class="clickable"
                              @click="contractExpiringExpand = !contractExpiringExpand"/>
      <div class="text-secondary small">
        Zeitpunkt {{ date }}
      </div>
      <TransitionExpand>
        <b-list-group v-if="contractExpiringExpand" class="mt-1">
          <b-list-group-item v-for="contract in contractExpiring" :key="contract.id"
                             class="d-flex justify-content-between align-items-center list-item">
            {{ contract.displayname }}
            <b-badge :id="'tt-contractExpire-' + contract.id" :variant="variantDanger" pill>
              {{ contract.expiring >= 0 ? 'in' : 'vor' }} {{ Math.abs(contract.expiring) }} Tagen
            </b-badge>
            <b-tooltip :target="'tt-contractExpire-' + contract.id" triggers="hover">{{ contract.expire | date }}</b-tooltip>
          </b-list-group-item>
        </b-list-group>
      </TransitionExpand>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import TransitionExpand from "@/components/utility/TransitionExpand.vue";

export default {
  name: "ExpireInformation",
  props: ['groups'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      contractExpiringExpand: true
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    date() {
      return dayjs().format('DD.MM.YYYY')
    },
    contractExpiring() {
      let contractExpiring = []
      _.forEach(this.groups, (group) => {
        _.forEach(group.contracts, (contract) => {
          if(dayjs(contract.expire).diff(dayjs(),'day') < 60 && contract.inactive === false) {
            contractExpiring.push({id: contract.id, displayname: contract.displayname, expire: contract.expire,
              expiring: dayjs(contract.expire).diff(dayjs(),'day')})
          }
        })
      })
      return contractExpiring
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
