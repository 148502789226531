<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="p-0 py-2">
        <b-row v-for="(value, key) in groupedData" :key="key">
          <b-col class="text-left text-bold">
            {{ key }}
          </b-col>
          <b-col class="text-right">
            <span v-if="key === 'Läuft aus am' || key === 'Gekündigt zum'">
              <span v-if="value">{{ value | date }}</span>
              <span v-else>-</span>
            </span>
            <span v-else-if="key === 'Notiz'">
              <span v-if="value" style="white-space: pre-line">{{ value }}</span>
              <span v-else>-</span>
            </span>
            <span v-else>
              <span v-if="value">{{ value }}</span>
              <span v-else>-</span>
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile.vue";

export default {
  name: "TileContract",
  props: ['title', 'data'],
  components: {
    Tile
  },
  computed: {
    groupedData() {
      if(this.data) {
        return {
          'Status': this.data['inactive'] ? 'Nicht aktiv' : 'Aktiv',
          'Vertragsdauer': this.data['duration'],
          'Läuft aus am': this.data['expire'],
          'Gekündigt zum': this.data['terminated_for'] ? this.data['terminated_for'] : null,
          'Notiz': this.data['note']
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Tiles';
</style>
