<template>
  <b-row>
    <b-col cols="auto" class="ml-auto pr-0">
      <b-badge v-if="contract.infinite" variant="neutral">∞</b-badge>
      <b-badge v-else variant="neutral">{{ contract.count }}</b-badge>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "BadgeCounterContract",
  props: ['contract']
}
</script>

<style lang="scss" scoped>
</style>
