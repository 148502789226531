<template>
  <b-card-body class="card-expand text-white pt-3 px-3 pb-2">
    <b-container fluid class="p-0">
      <b-row class="mb-1">
        <b-col>
          <TileContract title="Allgemein" class="mb-3" :data="contract"/>
        </b-col>
      </b-row>
    </b-container>
  </b-card-body>
</template>

<script>
import TileContract from "@/components/fg-software/contracts/TileContract.vue";

export default {
  name: "ContractDetails",
  props: ['contract'],
  components: {TileContract}
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Details';
</style>
