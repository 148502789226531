<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8" style="font-size: 14px">
        <SubHeader class="mb-2">
          <GroupFilter class="mt-2" groupContent="contract" @search-changed="setSearch"
                       @filter-options="setFilterOptions" @add-group="addGroup" @add-group-content="addContract"/>
          <ExpireInformation :groups="groups"/>
        </SubHeader>
        <div v-if="groupsFiltered">
          <div v-for="group in groupsFiltered" :key="group.id" class="mt-3">
            <Group :group="group" @add-group-content="addContract" @edit-group="editGroup">
              <template v-slot:groupInfo>
                <b-row v-for="(value, key) in groupInfo(group)" :key="key">
                  <b-col class="text-left">
                    {{ key }}
                  </b-col>
                  <b-col class="text-right">
                    <span v-if="value">{{ value }}</span>
                    <span v-else>-</span>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:content>
                <div v-if="group.contracts.length > 0">
                  <div v-for="contract in group.contracts" :key="contract.id">
                    <ContractRow :contract="contract" :groupId="group.id" @edit-contract="editContract"/>
                  </div>
                </div>
                <b-row v-else cols="1">
                  <b-col>
                    <h6 class="text-secondary text-center">Kein Vertrag vorhanden</h6>
                  </b-col>
                </b-row>
              </template>
            </Group>
          </div>
        </div>
        <b-row v-else cols="1">
          <b-col>
            <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <ContractGroupModal :method="method" :data="groupData" @refresh="refresh"/>
    <ContractModal :method="method" :data="contractData" :groupId="groupId" @refresh="refresh"/>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {postRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import ExpireInformation from "@/components/fg-software/contracts/ExpireInformation.vue";
import GroupFilter from "@/components/fg-software/GroupFilter.vue";
import Group from "@/components/fg-software/Group.vue";
import ContractRow from "@/components/fg-software/contracts/ContractRow.vue";
import ContractGroupModal from "@/components/fg-software/contracts/modals/ContractGroupModal.vue";
import ContractModal from "@/components/fg-software/contracts/modals/ContractModal.vue";

export default {
  name: "ContractsManage",
  components: {
    ContractModal,
    ContractGroupModal,
    Group,
    ContractRow,
    SubHeader,
    ScrollToTop,
    GroupFilter,
    ExpireInformation,
  },
  data() {
    return {
      search: {
        groupSearch: '',
        contentSearch: ''
      },
      groups: [],
      method: null,
      groupId: null,
      groupData: null,
      contractData: null,
      filterOptions: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    setSearch(data) {
      this.search = data
    },
    setFilterOptions(data) {
      this.filterOptions = data
      this.refresh()
    },
    addGroup() {
      this.method = 'add'
      this.$bvModal.show('contractGroupModal')
    },
    addContract() {
      this.method = 'add'
      this.$bvModal.show('contractModal')
    },
    editGroup(data) {
      this.method = 'edit'
      this.groupData = data
      this.$bvModal.show('contractGroupModal')
    },
    editContract(data) {
      this.method = 'edit';
      this.contractData = data;
      this.$bvModal.show('contractModal')
    },
    groupInfo(group) {
      if(group) {
        return {
          'Status': !group['inactive'] ? 'Aktiv' : 'Nicht aktiv',
          'Notiz': group.note
        }
      }
      return null
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getContractsData()
      this.setLoadingState(false)
    },
    async getContractsData() {
      let data
      if(!this.filterOptions) {
        data = {
          inactive: false
        }
      }
      else {
        data = this.filterOptions
      }
      this.groups = []
      await postRequest('/contracts/fg/all', data, this, null)
          .then((response) => {
            this.groups = response.data.groups
          })
    }
  },
  computed: {
    groupsFiltered() {
      let groupList = _.filter(this.groups, (group) => {
        return group.displayname.toLowerCase().includes(this.search.groupSearch.toLowerCase())
      })
      return _.filter(groupList, (group) => {
        if(group.contracts.length > 0) {
          return _.filter(group.contracts, (contract) => {
            return contract.displayname.toLowerCase().includes(this.search.contentSearch.toLowerCase())
          }).length > 0
        }
      })
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
